import React, { FC, useEffect, useState } from 'react';
import { Alert, Card, Container, IFields, useForm, useLoader } from '@legalshield/adonis-ux-framework';
import { useNavigate } from 'react-router-dom';

import RealmUtility from '../../../utils/realm';
import { ButtonBar } from '../../Controls/ButtonBar';
import { PasswordForm } from '../../Controls/PasswordUtil/PasswordForm';
import { useGetIdentity, useUpdatePassword } from '../../../hooks/useIdentity';
import { Layout } from '../../Layout/Layout';

export const Password: FC = () => {
  const passwordFields = PasswordForm.passwordFields;
  passwordFields.password.label = string_table.PWFORM_NEWPASSWORD;
  const { data: sData } = useGetIdentity();
  const [isGroupAdmin] = useState(RealmUtility.isGroupAdmin());
  const [isPartner] = useState(RealmUtility.isPartner());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const loader = useLoader();
  const navigate = useNavigate();

  const isNewPassword = (isPartner || isGroupAdmin) && !sData?.hasPassword;
  const formFields: Record<string, IFields> = isNewPassword
    ? passwordFields
    : {
        existing: {
          errorMessage: string_table.SECURITY_EXISITING_REQUIRED,
          label: string_table.SECURITY_EXISTING,
          required: true,
          type: 'password',
          value: '',
        },
        ...passwordFields,
      };
  const [formValues, formObject] = useForm(formFields, null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const { password, existing, retypePassword } = formValues;

  const { mutate: pwMutate, isSuccess, isError, data } = useUpdatePassword(sData?.version);

  const changePassword = () => {
    if (password.value !== retypePassword.value) {
      setErrorMessage(string_table.PASSWORDS_DO_NOT_MATCH);
      formObject.setValues({
        retypePassword: {
          ...formValues.retypePassword,
          errorMessage: string_table.PASSWORDS_DO_NOT_MATCH,
        },
      });
      return;
    }
    pwMutate(
      { currentPassword: existing?.value, newPassword: password.value },
      {
        onError: () => {
          setErrorMessage(string_table.ALERT_ERROR);
        },
      },
    );
  };
  const onNavigateBack = (message?: string, isError?: boolean): void => {
    if (message) {
      if (isError) {
        loader.Error(message);
      } else {
        loader.Success(message);
      }
    }
    navigate('/security');
  };

  useEffect(() => {
    if (isError || data?.status >= 400) {
      setErrorMessage(string_table.ALERT_PASSWORD_INVALID);
      formObject.setValues({
        ...formValues,
        existing: {
          ...formValues.existing,
          errorMessage: string_table.ALERT_PASSWORD_INVALID,
        },
        password: {
          ...formValues.password,
          errorMessage: string_table.ALERT_PASSWORD_INVALID,
        },
      });
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 2000); // Hide alert after 2 seconds
      return () => clearTimeout(timer); // Cleanup the timer
    }
    if (isSuccess) {
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
        onNavigateBack(string_table.ALERT_PASSWORD_SUCCESS, false);
        window.location.reload();
      }, 2000); // Show alert for 2 seconds before reloading
    }
  }, [isSuccess, isError, data, onNavigateBack]);

  if (pplsi.authNPayload.act && pplsi.authNPayload.act.sub) {
    return <p>Not available in impersonation mode</p>;
  }

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.SECURITY_TITLE, link: '/security' },
    { label: string_table.PASSWORD_TITLE, link: '/security/password' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.SECURITY_TITLE} subtitle={string_table.SECURITY_DESCRIPTION}>
      <div className="password_holder">
        {showSuccessAlert && (
          <Alert title={string_table.ALERT_TITLE} severity="success" classNames={['mb-4']}>
            {string_table.PASSWORD_CHANGE_SUCCESS}
          </Alert>
        )}
        {errorMessage && (
          <Alert title="Password Change" severity="error" classNames={['mb-4']}>
            {errorMessage}
          </Alert>
        )}
        <Card>
          <Card.Content>
            <Card.Title>{string_table.SECURITY_PASSWORD}</Card.Title>
            <Container classNames="pl-4 pr-4">{formObject.renderForm()}</Container>
            <ButtonBar
              id="pswrdBtnBar"
              onCancel={() => onNavigateBack()}
              onSave={() => changePassword()}
              disableSave={!formObject.isValid}
            />
          </Card.Content>
        </Card>
      </div>
    </Layout>
  );
};
