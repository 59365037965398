import React, { FC, useEffect, useState } from 'react';
import { Button, Heading, Icon } from '@legalshield/adonis-ux-framework';
import Cookies from 'js-cookie';

import './UpgradeBanner.scss';
import { useNavigate } from 'react-router-dom';
import { UpgradeableSubscription } from '../../Payments/utils/getUpgradeableSubscriptions';

interface UpgradeBannerProps {
  subscriptionId: string;
  upgradesData: UpgradeableSubscription[];
}

const UpgradeBanner: FC<UpgradeBannerProps> = ({ subscriptionId, upgradesData }) => {
  const cookieName = 'dismissedBanners';

  // Initialize state based on whether the banner has been dismissed or not
  const [showBanner, setShowBanner] = useState<boolean>(() => {
    const dismissed = Cookies.get(cookieName);
    return dismissed ? !dismissed.split(',').includes(subscriptionId) : true;
  });

  useEffect(() => {
    // Ensure state stays in sync if ID changes
    const dismissed = Cookies.get(cookieName);
    if (dismissed && dismissed.split(',').includes(subscriptionId)) {
      setShowBanner(false);
    }
  }, [subscriptionId]);

  const updateDismissedBanners = (id: string) => {
    const dismissed = Cookies.get(cookieName);
    const dismissedArray = dismissed ? dismissed.split(',') : [];

    // If ID isn't already in the array, add it and update the cookie
    if (!dismissedArray.includes(id)) {
      dismissedArray.push(id);
      Cookies.set(cookieName, dismissedArray.join(','), { expires: 365 });
    }
  };

  const dismissBanner = () => {
    setShowBanner(false);
    updateDismissedBanners(subscriptionId);
  };

  const navigate = useNavigate();

  if (!showBanner) {
    return null;
  }

  return (
    <div className={`upgrade-banner`}>
      <div className="upgrade-banner__content">
        <div className="upgrade-banner__content__left">
          <Heading as="T20" text={string_table.UPGRADE_BANNER_TITLE} />
          <Button
            label={string_table.UPGRADE_BANNER_BUTTON_TEXT}
            classNames={['mt-4']}
            onClick={
              upgradesData.length > 1
                ? () => navigate('/payments')
                : () => navigate(`/payments/${subscriptionId}/upgrade`, { state: { fromOverview: true } })
            }
          />
        </div>
        <div className="upgrade-banner__content__right">
          <Icon name="feedback_close" size="medium" onClick={dismissBanner} />
        </div>
      </div>
    </div>
  );
};

export default UpgradeBanner;
