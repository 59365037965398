import axios from 'axios';

type PrecancelStatus = {
  membershipId: number;
  pendingCancelFlag: string;
}

type GetPrecancelStatusesResponse = PrecancelStatus[];

const getPrecancelStatuses = async (membershipId: string): Promise<GetPrecancelStatusesResponse> => {
  const endpoint = `/v1/classic/membership/${membershipId}/precancelStatuses`;

  const axiosConfig = {
    headers: {
      accept: 'application/json',
    },
    withCredentials: true,
  };

  try {
    const response = await axios.get<GetPrecancelStatusesResponse>(endpoint, axiosConfig);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(`Error ${error.response.status}: ${error.response.data}`);
    } else if (error.request) {
      throw new Error('No response received from server.');
    } else {
      throw new Error(`Error: ${error.message}`);
    }
  }
};

const getMembershipApplication = async (membershipId: string): Promise<any> => {
  const endpoint = `/v1/classic/membership/${membershipId}/application`;

  const axiosConfig = {
    headers: {
      accept: 'application/json',
    },
    withCredentials: true,
  };

  try {
    const response = await axios.get(endpoint, axiosConfig);
    return response.data;
  } catch (error: any) {
    throw new Error(`Error: ${error.message}`);
  }
}

export default {
  getPrecancelStatuses,
  getMembershipApplication,
};
