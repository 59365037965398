import { Api as EntitlementsApi, Status } from '@legalshield/frontend-commons/dist/sdk/entitlements';
import { Api as ProductsApi } from '@legalshield/frontend-commons/dist/sdk/products';
import { Api as AtlasQueryApi } from '@legalshield/frontend-commons/dist/sdk/atlas-queries';
import { IEntitlement } from '../interfaces/entitlement.interface';
import { useQuery } from '@tanstack/react-query';

const entitlementsApi = new EntitlementsApi();
const productsApi = new ProductsApi();
const atlasQueryApi = new AtlasQueryApi();

async function fetchEntitlementsByUserId(): Promise<IEntitlement[]> {
  const { data: entitlements } = await entitlementsApi.v1.entitlementsGetEntitlements(pplsi.authNPayload.sub, {
    includeAuthorizedResources: true,
  });
  const activeEntitlements = entitlements.filter(
    (entitlement) => !entitlement.deleted && entitlement.status != Status.CANCELLED,
  ) as IEntitlement[];
  return await transformAll(activeEntitlements);
}

async function transformAll(entitlements: IEntitlement[]): Promise<IEntitlement[]> {
  return Promise.all(
    entitlements.map(async (entitlement) => {
      try {
        const { data: productBrochure } = await productsApi.v1.productsGetProduct(entitlement.product.id);

        entitlement.productBrochure = productBrochure;

        if (entitlement.source?.groupId) {
          const queryParams: Record<string, string> = { groupId: entitlement.source.groupId };
          const { data: groupInformation } = await atlasQueryApi.v1.queriesAuthMatchServiceQueries(
            pplsi.authNPayload.sub,
            'groups',
            'findAll',
            queryParams,
          );
          entitlement.groupInformation = groupInformation[0].group;
        }

        return entitlement;
      } catch (error) {
        return Promise.resolve(entitlement);
      }
    }),
  );
}

export const useGetEntitlements = () => {
  return useQuery({
    queryKey: ['entitlements'],
    queryFn: fetchEntitlementsByUserId
  });
};