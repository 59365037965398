import React, { FC, useState, useEffect } from 'react';
import { Alert } from '@legalshield/adonis-ux-framework';
import { checkUpgradeStatus } from '../../Payments/utils/getUpgradeableSubscriptions';

interface ProvisioningBannerProps {
  friendlyIdArr: string[];
}

const ProvisioningBanner: FC<ProvisioningBannerProps> = ({ friendlyIdArr }) => {
  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    const handleUpgradeStatus = async () => {
      try {
        if (friendlyIdArr && friendlyIdArr.length > 0) {
          const status = await checkUpgradeStatus(friendlyIdArr);
          setShowBanner(status.planUpgradeIsPending);
        }
      } catch (error) {
        console.error('Error checking upgrade status:', error);
        setShowBanner(false);
      }
    };

    handleUpgradeStatus();
  }, [friendlyIdArr]);

  const handleDismiss = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Alert
      classNames={['mt-4']}
      dismissible
      onDismiss={handleDismiss}
      icon="calendar_clock"
      title={string_table.UPGRADE_PROVISIONING_BANNER_TITLE}
    >
      {string_table.UPGRADE_PROVISIONING_BANNER_TEXT}
    </Alert>
  );
};

export default ProvisioningBanner;
