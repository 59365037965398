import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import { ISubscriptionResource } from '../../hooks/utils/subscription-utils';
import { usePrecancelModal } from '../../../../components/Payments/PrecancelModal/PrecancelModalContext';
import { UpdatePaymentMethod } from '@legalshield/frontend-commons/dist/sdk/subscriptions';

type UsePrecancelQueryHandlerProps = {
  subscription?: ISubscriptionResource;
  isPrecancel: boolean;
  inPrecancelBiller: boolean;
  updatePaymentMethod: (updatedPaymentMethod: UpdatePaymentMethod) => Promise<void>;
};

export const usePrecancelQueryHandler = ({
  subscription,
  isPrecancel,
  inPrecancelBiller,
  updatePaymentMethod,
}: UsePrecancelQueryHandlerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resourceIdParam = searchParams.get('resource_id');
  const subscriptionIdParam = searchParams.get('subscriptionId');

  const { dispatch: precancelModalDispatch } = usePrecancelModal();

  // Use a ref to ensure we only process the update once.
  const hasProcessedRef = useRef(false);

  const clearQueryParams = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('resource_id');
    newSearchParams.delete('subscriptionId');
    setSearchParams(newSearchParams);
  };

  const triggerPrecancelModal = (resourceId: string) => {
    const onPayAndRenewSuccess = () => {
      updatePaymentMethod({ paymentMethodId: resourceId });
      clearQueryParams();
      hasProcessedRef.current = true;
    };

    precancelModalDispatch({
      type: 'SHOW_MODAL',
      payload: {
        subscription,
        paymentMethodId: resourceId,
        handleClickPayAndRenew: onPayAndRenewSuccess,
      },
    });
  };

  const performImmediateUpdate = (resourceId: string) => {
    updatePaymentMethod({ paymentMethodId: resourceId });
    clearQueryParams();
    window.history.replaceState(null, '', `${location.pathname}`);
    hasProcessedRef.current = true;
  };

  useEffect(() => {
    const hasProcessed = hasProcessedRef.current;
    const hasInvalidParams = !resourceIdParam || subscriptionIdParam !== subscription?.id;
    const shouldTriggerPrecancelModal = isPrecancel && !inPrecancelBiller;

    if (hasProcessed || hasInvalidParams) return;

    if (shouldTriggerPrecancelModal) {
      triggerPrecancelModal(resourceIdParam);
    } else {
      performImmediateUpdate(resourceIdParam);
    }
  }, [
    resourceIdParam,
    subscription,
    subscriptionIdParam,
    isPrecancel,
    inPrecancelBiller,
    precancelModalDispatch,
    updatePaymentMethod,
    searchParams,
    setSearchParams,
  ]);
};
