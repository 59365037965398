import {
  Api as EligibilitiesApi,
  EligibilitiesRequestProduct,
  Source,
} from '@legalshield/frontend-commons/dist/sdk/eligibilities';
import { ISubscriptionResource } from './utils/subscription-utils';
import { useQuery } from '@tanstack/react-query';

type EligibilityBenefitProps = {
  subscription: ISubscriptionResource;
  includeSupplements?: boolean;
};

const eligibilitiesApi = new EligibilitiesApi();

const getEligibilityBenefitsByProduct = async (
  payload: EligibilitiesRequestProduct, 
  includeSupplements = false
) => {
  try {
    const { data: eligibilityBenefits } =
      await eligibilitiesApi.v1.benefitsEligibilitiesBenefitsEligibilitiesByProduct2(
        payload,
        { includeAllSupplements: includeSupplements },
        {
          headers: {
            'Accept-Language': pplsi.market,
          },
          withCredentials: true,
        },
      );
    return eligibilityBenefits;
  } catch (error) {
    const errorMessage = `An error occurred while fetching eligibility benefits for ${payload.id}. Error details: ${error}`;
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
};

export const useGetEligibilityBenefits = ({ 
  subscription, 
  includeSupplements = false 
}: EligibilityBenefitProps) => {
  const source: Source = {
    channel: subscription?.source?.channel,
    groupId: subscription?.source?.groupId,
  };

  return useQuery({
    queryKey: ['eligibilityBenefits', subscription?.productId, includeSupplements],
    queryFn: async () => {
      if (!source.groupId) {
        const errorMessage = `'groupId' is missing for subscription with product ID ${subscription?.productId}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      const offerItems = subscription.offer.offerItems;
      const productOfferItems = offerItems.filter((offerItem) => offerItem.type === 'PRODUCT');
      const hasSupplement = offerItems.some((offerItem) => offerItem.type === 'SUPPLEMENT');

      const productPayloads: EligibilitiesRequestProduct[] = productOfferItems.map((offerItem) => {
        const supplementKey = hasSupplement ? offerItem.name || '' : '';
        const supplements = hasSupplement ? { [supplementKey]: true } : {};
        
        return {
          id: subscription?.productId,
          productFamily: subscription.productBrochure.productFamily,
          productType: offerItem.productDetail?.productType,
          productVersion: offerItem.productDetail?.productVersion,
          source: source,
          supplements: supplements,
          tier: offerItem.productDetail?.productTier,
        };
      });

      const benefits = await Promise.all(
        productPayloads.map((payload) => 
          getEligibilityBenefitsByProduct(payload, hasSupplement)
        ),
      );

      return benefits;
    },
    enabled: !!subscription?.productId && !!source.groupId,
  });
};
