import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Loader, useLoader } from '@legalshield/adonis-ux-framework';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

import LinkMembershipModal from '../Payments/Subscriptions/LinkMembershipModal/LinkMembershipModal';
import Memberships from './Memberships/Memberships';
import ProvisioningBanner from './ProvisioningBanner/ProvisioningBanner';
import RealmUtility from '../../utils/realm';
import UpgradeBanner from './UpgradeBanner/UpgradeBanner';
import { EntitlementPresenter } from '../../presenters';
import { IEntitlement } from '../../interfaces/entitlement.interface';
import { Layout } from '../Layout/Layout';
import { ManageAccount } from '../ManageAccount/ManageAccount';
import { checkUpgradeStatus, useUpgradeableSubscriptions } from '../Payments/utils/getUpgradeableSubscriptions';
import { useGetEntitlements } from '../../hooks/useEntitlements';
import { useLaunchDarkly } from '../../hooks/useLaunchDarkly';

interface BackButtonHeaderProps {
  /**
   * The label for the button
   */
  buttonLabel?: string;
  /**
   * The URL for the button
   */
  url: string;
}

export interface UpgradeStatus {
  planUpgradeIsPending: boolean;
}

const BackButtonHeader: FC<BackButtonHeaderProps> = ({ buttonLabel, url }) => (
  <Fragment>
    {buttonLabel && <Button label={buttonLabel} onClick={() => (window.location.href = url)} classNames={['mt-4']} />}
  </Fragment>
);

const UserOverview: FC = () => {
  const associateFamily = 'Associate';
  const loader = useLoader();
  const [plansPresenters, setPlansPresenters] = useState<EntitlementPresenter[]>([]);
  const [associatesPresenters, setAssociatesPresenters] = useState<EntitlementPresenter[]>([]);
  const { data: entitlements, isLoading, isError, isSuccess } = useGetEntitlements();

  useEffect(() => {
    if (entitlements) {
      buildPresenters(entitlements);
    }
    if (isError) {
      loader.Error(string_table.ALERT_ERROR);
    }
    if (isSuccess) {
      loader.Blank();
    }
  }, [entitlements, isError, isLoading, isSuccess]);

  function buildPresenters(entitlements: IEntitlement[]) {
    const plans: EntitlementPresenter[] = entitlements
      .filter((e) => {
        return e.product.productFamily !== associateFamily;
      })
      .map((e) => new EntitlementPresenter(e));
    const associateAgreements: EntitlementPresenter[] = entitlements
      .filter((e) => {
        return e.product.productFamily === associateFamily;
      })
      .map((e) => new EntitlementPresenter(e));
    setPlansPresenters(plans);
    setAssociatesPresenters(associateAgreements);
  }

  return (
    <Fragment>
      <Loader loaderState={loader.loaderState} />
      <Memberships
        products={plansPresenters}
        associates={associatesPresenters}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </Fragment>
  );
};

const Overview: FC = () => {
  const navigate = useNavigate();
  const realm = RealmUtility.getRealm();
  const location = useLocation();

  const { upgradeFlow } = useLaunchDarkly();

  const [pageSubtitle, setPageSubtitle] = useState<string>('');
  const [buttonLabel, setButtonLabel] = useState<string>(null);
  const [buttonHref, setButtonHref] = useState<string>(null);
  const [upgradeStatus, setUpgradeStatus] = useState<UpgradeStatus>({
    planUpgradeIsPending: true,
  });

  const { data: upgradesData } = useUpgradeableSubscriptions();

  const hasUpgradeablePlan = upgradesData && upgradesData.length > 0;
  const subscriptionId = upgradesData ? upgradesData[0]?.subscription.id : null;
  const friendlyIdArray = useMemo(
    () => (upgradesData ? upgradesData.map((upgradeData) => upgradeData.subscription.source.friendlyId) : []),
    [upgradesData],
  );

  useEffect(() => {
    switch (realm) {
      case Realm.GroupAdmin:
        setPageSubtitle(string_table.GROUPS_DESCRIPTION);
        setButtonLabel(string_table.GROUPS_BUTTON);
        setButtonHref('https://groups.' + realmDomain);
        break;
      case Realm.Partner:
        setPageSubtitle(string_table.PARTNER_DESCRIPTION);
        setButtonLabel(string_table.PARTNER_BUTTON);
        setButtonHref('https://partner.' + realmDomain);
        break;
      default:
        setPageSubtitle(string_table.OVERVIEW_DESCRIPTION);
        break;
    }
  }, [realm]);

  useEffect(() => {
    const handleUpgradeStatus = async () => {
      if (friendlyIdArray.length > 0 && subscriptionId) {
        const status = await checkUpgradeStatus(friendlyIdArray);
        setUpgradeStatus(status);
      }
    };

    handleUpgradeStatus();
  }, [location.state?.fromUpgrade, location.state?.fromOverview, friendlyIdArray, subscriptionId]);

  const showUpgradeBanner = hasUpgradeablePlan && !!upgradeFlow && !upgradeStatus.planUpgradeIsPending;

  const handleLinkMembershipModalChange = (isOpen: boolean) => {
    if (!isOpen) navigate('/overview');
  };

  return (
    <Layout
      title={string_table.OVERVIEW_TITLE}
      subtitle={pageSubtitle}
      bannerElement={
        <Fragment>
          {showUpgradeBanner && <UpgradeBanner subscriptionId={subscriptionId} upgradesData={upgradesData} />}
          <ProvisioningBanner friendlyIdArr={friendlyIdArray} />
        </Fragment>
      }
    >
      <BackButtonHeader buttonLabel={buttonLabel} url={buttonHref} />
      {realm === Realm.User && <UserOverview />}
      <ManageAccount />
      {location.pathname === '/link-membership' && (
        <LinkMembershipModal
          onOpenChange={handleLinkMembershipModalChange}
          closeFunction={() => navigate('/overview')}
        />
      )}
    </Layout>
  );
};

export default Overview;
