import React, { Fragment } from 'react';
import { Container, Divider, Grid, GridRow, Heading } from '@legalshield/adonis-ux-framework';

import { ProductBenefit } from '@legalshield/frontend-commons/dist/sdk/eligibilities';
import { ProductBenefits } from './ProductBenefits';
import { SupplementBenefits } from './SupplementBenefits';
import { ISubscriptionResource } from '../../hooks/utils/subscription-utils';
import { useGetEligibilityBenefits } from '../../hooks/useEligibiltyBenefits';

interface EligibilityBenefit {
  productBenefits: ProductBenefit;
}

type BenefitsProps = {
  subscription: ISubscriptionResource;
  isSupplementBenefits?: boolean;
  supplementBenefits?: ProductBenefit;
};

export const Benefits = ({ subscription, isSupplementBenefits = false, supplementBenefits }: BenefitsProps) => {
  // Explicitly type the hook response
  const { data: eligibleBenefits } = useGetEligibilityBenefits({ subscription }) as {
    data: EligibilityBenefit[] | undefined;
  };

  if (!eligibleBenefits) return <Fragment />;

  return (
    <Container classNames={['mr-6', 'mt-5']}>
      <Divider tint="light" />
      <br />
      <Heading as="T14" text={string_table.BENEFITS} />
      <Grid>
        <GridRow variant="halves">
          {eligibleBenefits.map((eligibleBenefit, index) => {
            const eligibleProductBenefits = eligibleBenefit?.productBenefits;

            return isSupplementBenefits && supplementBenefits ? (
              <SupplementBenefits key={index} supplementBenefits={supplementBenefits} />
            ) : (
              <ProductBenefits key={index} productBenefits={eligibleProductBenefits} />
            );
          })}
        </GridRow>
      </Grid>
    </Container>
  );
};
