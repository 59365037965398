import React, { FC, Fragment } from 'react';
import { Container, Title } from '@legalshield/adonis-ux-framework';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

import CardGrid, { renderGridCardButtons } from '../Card/CardGrid';
import RealmUtility from '../../utils/realm';
import { defaultNavButtons, userNavButtons, userNavButtonsWithFamilyLink } from '../NavigationBar/NavigationBar';
import { useGetEntitlements } from '../../hooks/useEntitlements';
import { useLaunchDarkly } from '../../hooks/useLaunchDarkly';

export const ManageAccount: FC = () => {
  const realm = RealmUtility.getRealm();
  const cards = realm === Realm.User ? userNavButtons : defaultNavButtons;
  const { familyManagementLink } = useLaunchDarkly();
  const { data: entitlements } = useGetEntitlements();
  const productType = 'Legal Plan Family';
  const hasActiveLSFamilyPlan =
    entitlements && !!entitlements?.find((e) => e.product?.productType === productType && e.status === 'ACTIVE');

  const hasOtherActiveProductType =
    entitlements && entitlements.some((e) => e.product?.productType !== productType && e.status === 'ACTIVE');

  const cardsWithFamilyLink = realm === Realm.User ? userNavButtonsWithFamilyLink : defaultNavButtons;

  return (
    <Fragment>
      <Container
        flexbox
        background="light-gray"
        alignItems="flex-start"
        justifyContent="flex-start"
        classNames={['mt-6']}
      >
        <Title text={string_table.MANAGE_YOUR_ACCOUNT} textSize="large" />
      </Container>
      <CardGrid>
        {renderGridCardButtons(
          familyManagementLink && !hasOtherActiveProductType && hasActiveLSFamilyPlan === true
            ? cardsWithFamilyLink
            : cards,
        )}
      </CardGrid>
    </Fragment>
  );
};
