import React, { FC, Fragment, useState } from 'react';
import { Container, Image, Text, Title, Icon, GridCol } from '@legalshield/adonis-ux-framework';

import CardLink from '../../../Card/CardLink';
// @ts-expect-error Will error until we add SVG support to declarations
import atIcon from '../../../../images/icon_at.svg';
import CardGrid from '../../../Card/CardGrid';
import Config from '../../../../config';
import LinkMembershipModal from '../../../Payments/Subscriptions/LinkMembershipModal/LinkMembershipModal';
import URLs from '../../../../utils/url';

export const D2CCard: FC = () => (
  <div className="lsux-card accounts-card p-5">
    <Container>
      <Container flexbox flexDirection="row">
        <Container classNames={['mr-4']}>
          <Icon name="apps_legal_shield" size="xlarge" color="G800" />
        </Container>
        <Icon name="apps_id_shield" size="xlarge" color="G800" />
      </Container>

      <Title textSize={'large'} text={string_table.OVERVIEW_NON_MEMBER_INVITATION_TITLE} classNames={'pt-4'} />
      <Text
        textSize="medium"
        text={string_table.OVERVIEW_NON_MEMBER_INVITATION_DESCRIPTION}
        classNames={['pt-3', 'accounts-card__text']}
      />
      <CardLink
        ids={false}
        text={string_table.OVERVIEW_NON_MEMBER_INVITATION_PROMPT_LS}
        to="https://legalshield.com"
        iconColor="P200"
      />
      <div className={'mr-4'}>
        <CardLink
          ids={true}
          text={string_table.OVERVIEW_NON_MEMBER_INVITATION_PROMPT_IDS}
          to="https://idshield.com"
          iconColor="B200"
        />
      </div>
    </Container>
  </div>
);

export const BizSolCard: FC = () => {
  const [showActivateModal, setShowActivateModal] = useState(false);

  return (
    <Fragment>
      {showActivateModal && (
        <LinkMembershipModal
          closeButton
          closeFunction={() => setShowActivateModal(false)}
          onOpenChange={setShowActivateModal}
        />
      )}
      <div className="lsux-card accounts-card p-5">
        <Container>
          <Image src={atIcon} style={{ width: '3.5rem' }} />
          <Title textSize={'large'} text={string_table.OVERVIEW_NON_MEMBER_ACTIVATION_TITLE} classNames={'pt-4'} />
          <Text
            textSize="medium"
            text={string_table.OVERVIEW_NON_MEMBER_ACTIVATION_DESCRIPTION}
            classNames={['pt-3', 'accounts-card__text']}
          />
          <CardLink
            ids={false}
            text={string_table.OVERVIEW_NON_MEMBER_ACTIVATION_PROMPT}
            to={'/access'}
            iconColor="P200"
          />
        </Container>
      </div>
    </Fragment>
  );
};

export const MembershipsEmptyState: FC = () => (
  <CardGrid>
    <GridCol>
      <BizSolCard />
    </GridCol>
    <GridCol>
      <D2CCard />
    </GridCol>
  </CardGrid>
);
