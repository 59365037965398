import React from 'react';
import { Icon, Text, Popover } from '@legalshield/adonis-ux-framework';
import './PasswordHelp.scss';

export interface PasswordHelpProps {
  caseValid: boolean;
  numberValid: boolean;
  lengthValid: boolean;
  specialValid: boolean;
}

export const PasswordHelp: React.FC<PasswordHelpProps> = ({
  caseValid,
  numberValid,
  lengthValid,
  specialValid,
}: PasswordHelpProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <div className="pwd-help" style={{ boxSizing: 'border-box', paddingTop: '10px', width: '350px' }}>
        <ul style={{paddingLeft: '0px'}}>
          <li className="mb-3">
            <Icon
              color={lengthValid ? 'G200' : 'R200'}
              name={lengthValid ? 'alert_check' : 'action_close'}
              size="small"
              classNames={['mr-3']}
            />
            {string_table.PWHELP_EIGHT_CHARACTERS}
          </li>
          <li className="mb-3">
            <Icon
              color={caseValid ? 'G200' : 'R200'}
              name={caseValid ? 'alert_check' : 'action_close'}
              size="small"
              classNames={['mr-3']}
            />
            {string_table.PWHELP_UPPER_AND_LOWER}
          </li>
          <li className="mb-3">
            <Icon
              color={numberValid ? 'G200' : 'R200'}
              name={numberValid ? 'alert_check' : 'action_close'}
              size="small"
              classNames={['mr-3']}
            />
            {string_table.PWHELP_ONE_NUMBER}
          </li>
          <li className="mb-3">
            <Icon
              color={specialValid ? 'G200' : 'R200'}
              name={specialValid ? 'alert_check' : 'action_close'}
              size="small"
              classNames={['mr-3']}
            />
            {string_table.PWHELP_ONE_SPECIAL}
          </li>
        </ul>
      </div>
    </div>
  );
};
