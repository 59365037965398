import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from '@legalshield/adonis-ux-framework';

export const LinkToAccess: FC = () => {
  const navigate = useNavigate();

  return (
    <Container flexbox alignItems="center" justifyContent="center" classNames={['mt-5']} background="none">
      <Container flexDirection="row" background="none">
        <Button
          variant="tertiary"
          iconColor="P200"
          iconLeft="alert_information"
          textSize="medium"
          label={string_table.MEMBERSHIPS_ACCESS_LINK}
          onClick={() => {
            navigate('/link-membership');
          }}
          classNames={['text-nowrap']}
        />
      </Container>
    </Container>
  );
};
