import { FactorResource, MultifactorSettingResource } from '@legalshield/frontend-commons/dist/sdk/identities';
import { Factors, Identities, SDKHeaders } from '@legalshield/frontend-commons';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { validateStatusResponse } from '../utils/utils';

const identitiesApi = new Identities.Api();
const factorsApi = new Factors.Api();

// Define query keys as objects for better type safety
const queryKeys = {
  multifactor: ['multifactor'] as const,
  identity: ['identity'] as const
};

export interface MultifactorResponse {
  factors: FactorResource[];
  settings: MultifactorSettingResource;
}

function defaultOptions(queryClient: QueryClient, queryToInvalidate?: readonly unknown[]) {
  return {
    onError: (err: unknown) => {
      console.log(`error ${err}`);
    },
    onSettled: () => {
      if (queryToInvalidate) {
        queryClient.invalidateQueries({ queryKey: queryToInvalidate });
      }
    },
  };
}

async function fetchFactorsAndSettings(): Promise<MultifactorResponse> {
  const { data: factors } = await factorsApi.v1.factorsGetFactors(pplsi.authNPayload.sub, validateStatusResponse());
  const { data: settings } = await identitiesApi.v1.multifactorGetMultifactorType(
    pplsi.authNPayload.sub,
    validateStatusResponse(),
  );

  return { factors: orderFactors(factors), settings } as MultifactorResponse;
}

export const useGetFactors = () => {
  return useQuery({
    queryKey: queryKeys.multifactor,
    queryFn: fetchFactorsAndSettings
  });
};

export const useGetIdentity = () => {
  return useQuery({
    queryKey: queryKeys.identity,
    queryFn: async () => (await identitiesApi.v1.identitiesFindByIdentityId(authNPayload.sub)).data
  });
};

export const useUpdateMultifactorSettings = () => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (payload: MultifactorSettingResource) => {
      const { data: settings } = await identitiesApi.v1.multifactorUpdateMultifactorType(
        authNPayload.sub,
        payload,
        validateStatusResponse(),
      );
      return settings;
    },
    ...defaultOptions(queryClient, queryKeys.multifactor)
  });
};

export const useCreateFactors = () => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (payload: Factors.FactorCreateRequest) => {
      return (await factorsApi.v1.factorsCreateFactor(pplsi.authNPayload.sub, payload, validateStatusResponse())).data;
    },
    ...defaultOptions(queryClient, queryKeys.multifactor)
  });
};

export const useVerifyFactors = () => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (payload: { factorId: string; code: { code: string } }) => {
      return factorsApi.v1.factorsVerifyFactor(
        pplsi.authNPayload.sub,
        payload.factorId,
        { value: payload.code.code },
        validateStatusResponse(),
      );
    },
    ...defaultOptions(queryClient, queryKeys.multifactor)
  });
};

export const useSendCode = () => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (factorId: string) => {
      return factorsApi.v1.factorsSendCode(pplsi.authNPayload.sub, factorId, validateStatusResponse());
    },
    ...defaultOptions(queryClient)
  });
};

export const useDeleteFactor = () => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (factorId: string) => {
      return factorsApi.v1.factorsDeleteFactor(pplsi.authNPayload.sub, factorId, validateStatusResponse());
    },
    ...defaultOptions(queryClient, queryKeys.multifactor)
  });
};

export const usePromoteFactor = () => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (factorId: string) => {
      return factorsApi.v1.factorsSetPrimary(pplsi.authNPayload.sub, factorId, validateStatusResponse());
    },
    ...defaultOptions(queryClient, queryKeys.multifactor)
  });
};

export const useUpdatePassword = (version: number) => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (updatePassRequest: Identities.UpdatePasswordRequest) =>
      await identitiesApi.v2.passwordsUpdatePassword(
        authNPayload.sub,
        updatePassRequest,
        SDKHeaders.getVersionConfig(version),
      ),
    ...defaultOptions(queryClient, queryKeys.identity)
  });
};

export const useAddEmail = () => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (addEmailRequest: { req: any; version: number }) =>
      (
        await identitiesApi.v1.emailAddEmail(
          authNPayload.sub,
          addEmailRequest.req,
          SDKHeaders.getVersionConfig(addEmailRequest.version),
        )
      ).data,
    ...defaultOptions(queryClient, queryKeys.identity)
  });
};

export const useRemoveEmail = () => {
  const queryClient = new QueryClient();
  
  return useMutation({
    mutationFn: async (removeEmailRequest: { req: any; version: number }) =>
      (
        await identitiesApi.v1.emailRemoveEmail(
          authNPayload.sub,
          removeEmailRequest.req,
          SDKHeaders.getVersionConfig(removeEmailRequest.version),
        )
      ).data,
    ...defaultOptions(queryClient, queryKeys.identity)
  });
};

function orderFactors(factors: FactorResource[]): FactorResource[] {
  if (!factors) {
    return [] as FactorResource[];
  }
  return factors.sort((x: FactorResource, y: FactorResource) => {
    return Number(y.primary) - Number(x.primary);
  });
}