import { Api as SubscriptionsApi, SubscriptionResource } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { ISubscriptionResource, setSubscriptionProduct } from './utils/subscription-utils';
import { useQuery } from '@tanstack/react-query';

const subscriptionsApi = new SubscriptionsApi();

const identityId = pplsi?.authNPayload?.sub;

export const getSubscriptionsByIdentityId = async (): Promise<ISubscriptionResource[]> => {
  const { data: subscriptions } = await subscriptionsApi.v1.subscriptionsGetSubscriptions(identityId, {
    withCredentials: true,
  });

  const activeSubscriptions = subscriptions.filter(
    (subscription: SubscriptionResource) => !subscription.deleted,
  ) as ISubscriptionResource[];

  return await getActiveSubscriptions(activeSubscriptions);
};

const getActiveSubscriptions = async (subscriptions: ISubscriptionResource[]) => {
  return Promise.all(
    subscriptions.map(async (subscription: ISubscriptionResource) => {
      try {
        await setSubscriptionProduct(subscription);
        return subscription;
      } catch (error) {
        return Promise.resolve(subscription);
      }
    }),
  );
};

export const useGetSubscriptionsByIdentityId = () => {
  return useQuery({
    queryKey: ['subscriptions'], 
    queryFn: getSubscriptionsByIdentityId
  });
};
